import React from "react";
import { Divider, Image, Typography } from "antd";
import { useSearchParams } from "react-router-dom";
import { Bottom, BottomTag, RegistrationFooterWrap } from "../../StyledComponent";

const { Text } = Typography;

const CLOUD_IMAGES: any = {
  azure: "/images/azure.svg",
  aws: "/images/aws.svg",
  gcp: "/images/gcp.svg",
};

function RegistrationFooter({ pageType, brandColor, hideClazarBranding }: any) {
  const [searchParams] = useSearchParams();
  const cloud = searchParams.get("cloud");
  return (
    <>
      <RegistrationFooterWrap
        isClean={pageType === "clean"}
        className={`${pageType === "clean" && "clean-bottom"}`}
      >
        {/* {domain !== "datadome.io" && ( */}
        <BottomTag className={`${pageType !== "bold" ? "success-footer" : "bold-footer"}`}>
          <Text>{hideClazarBranding ? "" : "Powered by"}</Text>
          <Image
            preview={false}
            src='/images/clazar-img.svg'
            style={{ display: hideClazarBranding ? "none" : "block" }}
          />
          {cloud ? (
            <>
              <Divider type='vertical' style={{ display: hideClazarBranding ? "none" : "block" }} />
              <Image preview={false} src={CLOUD_IMAGES[cloud]} className='azure-img' height={36} />
            </>
          ) : null}
        </BottomTag>
        {/* )} */}
      </RegistrationFooterWrap>
      {pageType === "clean" && <Bottom brandColor={brandColor} />}
    </>
  );
}

export default RegistrationFooter;
