/* eslint-disable react/require-default-props */
import React from "react";
import { Button, Form, Input, Select } from "antd";
import tinycolor from "tinycolor2";
import { KeyboardArrowDownOutlined } from "@mui/icons-material";
import { CustomTitle, FormBottomWrap, FormItemWrap, FormWrap, Section } from "./StyledComponent";

const formData = [
  {
    label: "Company *",
    placeholder: "input content",
  },
  {
    label: "Full name *",
    placeholder: "input content",
  },
  {
    label: "Email Address *",
    placeholder: "input content",
  },
];

// program to convert first letter of a string to uppercase
function capitalizeFirstLetter(str: string) {
  // converting first letter to uppercase
  const capitalized = str.charAt(0).toUpperCase() + str.slice(1);

  return capitalized;
}

interface IRegistrationFormTypes {
  buttonColor?: string;
  preview?: boolean;
  handleSuccessMessage?: any;
  registrationData?: any;
  successMessage?: boolean;
  onFinish: any;
  pageType?: string;
  isLoading?: boolean;
}

const defaultPropsTypes = {
  buttonColor: "green",
};

function RegistrationForm({
  buttonColor,
  preview,
  handleSuccessMessage,
  registrationData,
  successMessage,
  onFinish,
  pageType,
  isLoading,
}: IRegistrationFormTypes) {
  const [form] = Form.useForm();

  const handleOptions = (options: any) => {
    const entries = Object.entries(options);
    const option = entries?.map((item: any) => ({
      label: item?.[1],
      value: item?.[1],
    }));
    return option;
  };

  const messages: any = {
    url: "Please enter a valid url",
    phone_number: "Please enter a valid phone number",
    email: " Please enter a valid email",
    text: "",
    dropdown: "",
  };

  if (registrationData?.gcp_user_details) {
    form.setFieldsValue({
      "Full Name": registrationData?.gcp_user_details?.name,
      "Email Address": registrationData?.gcp_user_details?.email,
    });
  }

  return (
    <FormWrap
      gap='32'
      labelFontSize='14'
      labelLineHeight='22'
      padding='48px 68px 88px'
      className={`${pageType === "clean" && "clean-formwrap"}`}
    >
      <Form
        layout='vertical'
        form={form}
        onFinish={onFinish}
        validateTrigger='onChange'
        scrollToFirstError
        className='registration-main-form'
      >
        <CustomTitle
          color='#000000'
          fontSize={24}
          lineheight={32}
          fontWeight={600}
          fontFamily='nunito-sans'
          className='form-header'
        >
          Complete your registration
        </CustomTitle>
        <Section>
          <FormItemWrap gap='24' className={`${pageType === "clean" && "clean-section"}`}>
            {registrationData?.registration_page_fields?.length
              ? registrationData?.registration_page_fields?.map((item: any) => (
                  <Form.Item
                    label={item?.title}
                    name={item?.title}
                    rules={[
                      {
                        required: item.required,
                        message: `${capitalizeFirstLetter(
                          item?.title || "This field",
                        )} is required`,
                      },
                      {
                        message: messages[item?.data_type] || "Please enter a valid input",
                        type: item.data_type,
                      },
                    ]}
                  >
                    {item?.data_type === "dropdown" ? (
                      <Select
                        popupClassName='form-select'
                        placeholder={`Please enter your ${item?.title}`}
                        options={handleOptions(item?.options || {})}
                        suffixIcon={
                          <KeyboardArrowDownOutlined
                            sx={{ height: "20px", width: "20px", color: "#AEB6BE" }}
                          />
                        }
                      />
                    ) : (
                      <Input
                        placeholder={`Please enter your ${item?.title}`}
                        name={item?.title}
                        // type={item?.data_type}
                      />
                    )}
                  </Form.Item>
                ))
              : formData.map((item) => (
                  <Form.Item label={item.label}>
                    <Input placeholder={item.placeholder} />
                  </Form.Item>
                ))}
          </FormItemWrap>
        </Section>
        <FormBottomWrap>
          <Button
            htmlType='submit'
            // onClick={() => handleSuccessMessage()}
            color={buttonColor}
            style={{
              backgroundColor: buttonColor || "#0D1A5B",
              fontSize: "16px",
              fontWeight: "600",
              height: "48px",
              color: `${tinycolor(buttonColor).isLight() ? "#000000" : "#ffffff"}`,
            }}
            loading={isLoading}
          >
            Complete registration
          </Button>
          <CustomTitle
            fontSize={12}
            fontWeight={400}
            lineheight={20}
            color='#212529'
            style={{
              textAlign: "center",
            }}
            className='form-bottom'
          >
            By submitting this form, I agree that my information will be shared with{" "}
            {registrationData?.title || registrationData?.domain} in accordance with the{" "}
            <span className='text-style'>Privacy Policy</span> and{" "}
            <span className='text-style'>Terms of Use.</span>
            {/* <span className='text-style'>Privacy Policy</span> and{" "}
        <span className='text-style'>Terms of Use.</span> */}
          </CustomTitle>
        </FormBottomWrap>
      </Form>
    </FormWrap>
  );
}

RegistrationForm.defaultProps = defaultPropsTypes;

export default RegistrationForm;
