import React from "react";
import Lottie from "lottie-react";
import { ConfigProvider, Spin } from "antd";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./home";
import Preview from "./preview";
import NotFound from "./not-found";
import loadingAnimation from "./loading.json";

import "./app.scss";

Spin.setDefaultIndicator(
  <Lottie style={{ height: "200px", width: "200px" }} animationData={loadingAnimation} loop />,
);

function App() {
  return (
    <ConfigProvider>
      <BrowserRouter>
        <Routes>
          <Route path='/registration' element={<Home />} />
          <Route path='/preview' element={<Preview />} />
          <Route path='/error' element={<NotFound />} />
          <Route path='*' element={<NotFound />} />
        </Routes>
      </BrowserRouter>
    </ConfigProvider>
  );
}

export default App;
