import React from "react";
import styled from "@emotion/styled";
import { Typography, Image, Card } from "antd";
import {
  CommonThemeWrapPropsType,
  CustomTitlePropsType,
  FormWrapPropsType,
  GapPropsType,
} from "./style.types";

const { Text } = Typography;

export const Section = styled.div``;

export const CustomTitle = styled(Text)(
  {},
  ({ mb, fontWeight, fontSize, lineheight, color, mt, fontFamily }: CustomTitlePropsType) => ({
    marginBottom: `${mb || 0}px !important`,
    fontWeight: `${fontWeight || 600} !important`,
    fontSize: `${fontSize || 48}px !important`,
    lineHeight: `${lineheight || 56}px !important`,
    color: `${color || "#ffffff"} !important`,
    marginTop: `${mt || 0}px !important`,
    fontFamily: fontFamily ? `${fontFamily} !important` : "nunito-sans !important",
    ".text-style": { textDecoration: "underline solid #4A5158 0.5px" },
  }),
);

export const FlexWrap = styled(Section)(
  {},
  ({ flexDir, alignItem }: { flexDir?: string; alignItem?: string }) => `
  display: flex;
  flex-direction: ${flexDir || "row"};
  align-items: ${alignItem || "center"}
`,
);

export const RegistrationFormWrap = styled(Section)`
  width: 100%;
  padding-left: 16px;
  padding-right: 16px;
  @media screen and (max-width: 768px) {
    margin-top: 0px;
  }
`;

export const RegistrationTitleWrap = styled(Section)(
  {},
  ({ gap }: GapPropsType) => `
  display: flex;
  flex-direction: column;
  width: 100%;
  @media only screen and (max-width: 767px) {     // for desktop type
    gap: ${gap || 24}px;
    padding-left: 16px !important;
    padding-right: 16px !important;
    position: relative;
  }
  @media only screen and (max-width: 912px) {       // for mobile type
  gap: ${gap || 24}px;
  padding-left: 0px;
  padding-right: 0px;
  position: relative;
  .ant-typography {
    padding-left: 16px !important;
    padding-right: 16px !important;
  }
}
`,
);

export const SubtitleWrap = styled(Section)(
  {},
  ({ gap, background, pageType }: GapPropsType) => `
  display: flex;
  flex-direction: column;
  gap: ${gap || "16"}px;
  width: fit-content;
  z-index: 1;
  position: absolute;
  bottom: 191px;
  max-width: 482px;
  width: 80%;
  margin-right: 32px;
  background: ${background};
  // @media screen and (max-width: 767px){  // desktop type
  @media screen and (max-width: 912px){      // mobile type
    position: static;
    gap: 8px !important;
    bottom: 0;
    margin-top: ${pageType === "classic" ? "32px" : "0px"};
    .registration-subtitle {
      font-weight: 400 !important;
      font-size: 16px !important;
      line-height: 24px !important;
      color: ${pageType === "bold" ? "#ffffff" : "#0D1A5B"} !important;
    }
      .registration-subtitle-tagline {
      font-weight: 400 !important;
      font-size: 16px !important;
      line-height: 24px !important;
      color: ${pageType === "bold" ? "#ffffff" : "#4A5158"} !important;
    }
  }
`,
);

export const TitleWrap = styled(Section)(
  {},
  ({ gap }: GapPropsType) => `
  display: flex;
  flex-direction: column;
  gap: ${gap || 8}px;
  margin-top: 106px;
  width: 80%;
  .registration-tagline {
    // word-break: break-all;
    text-align: justify;
    margin-right: 30px;
  }
  @media screen and (max-width: 768px){
    margin-top: 24px;
    .registration-title {
      font-weight: 400 !important;
      font-size: 32px !important;
      line-height: 36px !important;
    }
    .registration-tagline {
    }
  }
`,
);

export const FormItemWrap = styled(Section)(
  {},
  ({ gap }: { gap?: string }) => `
  display: flex;
  flex-direction: column;
  gap: ${gap || "24"}px;
  max-height: calc(100vh - 500px);
  min-height: auto;
  overflow-y: auto;
  padding-right: 5px;
  .ant-form-item {
    margin-bottom: 0px !important;
  }
  ::-webkit-scrollbar {
    width: 5px;
  }
  ::-webkit-scrollbar-thumb {
    width: 6px;
    background-color: #8c8c8c;
    border-radius: 10px;
  }
  ::-webkit-scrollbar-track {
    background-color: #d9d9d9;
  }
  .ant-input[disabled]:hover {
    border-color: #ced4db !important;
    border-inline-end-width: none;
  }
  .button-disable {
    pointer-events: none !important;
  }
  @media screen and (max-width: 768px) {
    max-height: calc(100vh - 620px) !important;
    min-height: 265px;
  }
  .ant-form-item .ant-form-item-label >label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
    display: none;
  }
  .ant-form-item .ant-form-item-label >label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::after {
    display: inline-block;
    margin-inline-start: 4px;
    color: #343B41;
    font-size: 14px;
    font-family: SimSun,sans-serif;
    line-height: 1;
    content: "*";
  }
`,
);

export const FormWrap = styled(Section)(
  {},
  ({ gap, labelFontSize, labelLineHeight }: FormWrapPropsType) => `
  background: #ffffff;
  box-shadow: 0px 16px 24px rgba(33, 37, 41, 0.12) !important;
  padding: 48px 68px 88px;
  border-radius: 8px;
  z-index: 1;
  position: relative;
  max-height: calc(100vh - 200px);
  zoom: 0.9;
  height: auto;
  display: flex;
  flex-direction: column;
  gap: 16px;
  .clean-formwrap {
    max-height: calc(100vh - 202px);
  }
  .ant-form {
    display: flex;
    flex-direction: column;
    gap: ${gap || "32"}px;
  }
  h3.ant-typography {
    margin-bottom: 0;
    margin-left: 0;
  }
  .ant-form-item-label > label{
    color: #212529;
    font-weight: 400;
    font-size: ${labelFontSize || "16"}px !important;
    line-height: ${labelLineHeight || "24"}px;
    text-transform: capitalize;
  }
  .ant-form-item-control-input {
    min-height: 40px;
  }
  .ant-input {
    padding: 8px 12px;
    background: #ffffff;
    border: 1px solid #ced4db;
    border-radius: 2px;
    font-weight: 400;
    height: 40px;
  }
  .ant-input::placeholder {
    color: #8f979e;
  }
  .ant-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px 24px;
    width: fit-content;
    height: 40px;
    border-radius: 4px;
    margin: 0 auto;
    width: 100%;
  }
  .ant-typography {
    color: #000000;
    margin: 0;
  }
  .ant-typography a {
    text-decoration: underline;
  }
  .form-header {
    font-weight: 400;
  }
  .clean-section {
    max-height: calc(100vh - 625px) !important;
  }
  @media only screen and (max-width: 1280px) {
    zoom: 0.87;
  }
  @media only screen and (max-width: 1040px) {
    zoom: 0.85;
  }
  @media only screen and (max-height: 920px) {
    max-height: calc(100vh - 300px) !important;
    zoom: 0.9;
  }
  @media only screen and (max-height: 730px) {
    zoom: 0.85;
  }
  @media only screen and (max-height: 576px) {
    zoom: 0.8;
  }
  @media only screen and (max-width: 768px) {
    padding: 24px;
    zoom: 1;
    border: 1px solid #E9ECEF;
    height: 60vh;
    min-height: 561px;
    .form-bottom {
      font-size: 15px !important;
      line-height: 23px !important;
    }
    .form-bottom .text-style {
      font-weight: 600 !important;
    }
    .ant-btn  {
      height: 48px !important;
    }
  }
`,
);

export const Bottom = styled(Section)(
  {},
  ({ brandColor }: { brandColor: string }) => `
  background: ${brandColor};
  height: 66px;
  width: 100%;
  position: absolute;
  bottom: 0;
`,
);

export const PoweredTag = styled(Section)`
  display: flex;
  align-items: center;
  margin-left: auto;
  justify-content: flex-end;
  background-color: #ffffff;
  z-index: 999;
  position: relative;
  width: 100%;
  span.ant-typography {
    font-weight: 400;
    font-size: 0.875em;
    line-height: 1.375em;
    color: #212529;
    z-index: 1;
  }
  .ant-image {
    z-index: 1 !important;
    width: auto !important;
    padding: 0 !important;
    border: 0 !important;
    background: transparent !important;
  }
  .ant-image-img {
    height: 48px;
    @media screen and (max-width: 768px) {
      height: 38px;
    }
  }
`;

export const BottomTag = styled(PoweredTag)`
  .ant-image {
    height: auto;
  }
  .ant-image-img {
    height: 48px;
  }
  .ant-divider-vertical {
    margin: 0 18px 0 8px;
    height: 24px;
    border-inline-start: 1px solid #8f979e;
  }
  .azure-img {
    height: 36px;
  }
  @media screen and (max-width: 768px) {
    display: none;
  }
`;

export const CommonThemeWrap = styled(Section)(
  {},
  ({ linearGradient, pageType, brandColor, successMessage }: CommonThemeWrapPropsType) => `
  position: relative;
  background: linear-gradient(${linearGradient}, ${
    pageType === "clean" ? "white" : brandColor || "#5E6FD0"
  } ${successMessage || pageType === "classic" ? "50%" : "44.5%"}, ${
    pageType === "classic" ? "#fff" : "white"
  } ${successMessage ? "50%" : "44.5%"});
  overflow: hidden;
  height: 100vh;
  .ant-image {
    border:none;
    background: #fff;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .ant-image-img {
    height: 65px;
  }
  .success-footer{
    margin-top:  -17px;
  }
  .ant-image .databricks {
    width: auto;
  }
  .registration-header-wrapper{
    background: #fff;
    box-shadow: 0px 2px 12px rgba(33, 37, 41, 0.08);
  }
  .registration-header-container{
    width: 100%;
    padding: 7.5px 127px;
    margin: 0 auto;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
  }
  @media screen and (max-width: 767px) {
    background: linear-gradient(180deg, ${
      pageType === "clean" ? "white" : brandColor || "#5E6FD0"
    } ${successMessage ? "50%" : "50%"}, ${
    pageType === "classic" ? brandColor || "#5E6FD0" : "white"
  } ${successMessage ? "50%" : "50%"});
    .registration-header-container {
      padding: 7.5px 31.5px;
      padding-right: 0px
    }
    .classic-mobile {
      background: linear-gradient(180deg, ${
        pageType === "clean" ? "white" : brandColor || "#5E6FD0"
      } ${successMessage ? "50%" : "30%"}, white ${successMessage ? "50%" : "70%"});
    }
  }
  @media screen and (max-height: 667px) {
    background: linear-gradient(180deg, ${
      pageType === "clean" ? "white" : brandColor || "#5E6FD0"
    } ${successMessage ? "50%" : "60%"}, ${pageType === "classic" ? "#fff" : "white"} ${
    successMessage ? "50%" : "40%"
  });
  }
  @media screen and (min-width: 1900px) {
    .registration-header-container {
      padding: 7.5px 10%;
    }
  }
  .column-registration {
    display: flex !important;
    .mobile-powered-tag {
      padding-right: 16px;
      @media screen and (min-width: 768px) {
        display: none;
      }
    }
  }
  .content-row {
    padding-left: 127px;
    padding-right: 127px;
    height: calc(100% - 80px);
    @media screen and (min-width: 1900px) {
      padding-left: 10% !important;
      padding-right: 10% !important;
    } 
    @media screen and (max-width: 913px) {
      overflow-y: auto;
      height: 100%;
      padding-left: 30px !important;
      padding-right: 30px !important;
    }
    // @media screen and (max-width: 768px) {
    //   overflow-y: auto;
    //   height: 100%;
    //   padding-left: 30px !important;
    //   padding-right: 30px !important;
    // }
    @media screen and (max-width: 913x) {
        overflow-y: auto;
        height: 100%;
        padding-left: 30px !important;
        padding-right: 30px !important;
      }
    @media screen and (max-width: 912px) {
      overflow-y: auto;
      height: 100%;
      padding-left: 0 !important;
      padding-right: 0 !important;
    }
  }
  .clean-bottom {
    bottom: 82px !important;
  }
  .registration-form {
    margin-top: 32px;
    @media screen and (max-width: 768px) {
      margin-top: 24px;
      margin-bottom: 0px;
    }
  }
  .reg-form-classic {
    @media screen and (max-width: 768px) {
      margin-bottom: 60px;
    }
  }
  .theme-bold {
    margin-top: 32px !important;
    width: 100%;
    @media screen and (max-width: 768px) {
      margin-bottom: 24px;
      margin-top: 0 !important;
    }
    @media screen and (min-height: 896px) and (max-height: 896px) {
      margin-bottom: 31px;
    }
    @media screen and (min-height: 740px) and (max-height: 740px) {
      margin-bottom: 58px;
    }
    @media screen and (min-height: 915px) and (max-height: 915px) {
      margin-bottom: 50px;
    }
  }
  @media screen and (max-width: 768px) {
    .theme-clean {
      margin-bottom: 137px;
    }
    .classic-modal {
      position: relative;
    }
  }
  @media screen and (max-width: 767px) {
    .theme-classic {
      background-color: #ffffff !important;
    }
  }
`,
);

export const StyledCurveImage = styled(Image)(
  {},
  ({ brandColor }: { brandColor: string }) => `
  position: absolute !important;
  bottom: 0 !important;
  background-color: ${brandColor} !important;
  height: auto !important;
  margin-bottom: 0px;
  object-fit: cover;  
  @media screen and (max-width: 768px) {
    top: 123px !important;
  }
  @media screen and (max-width: 912px) {
    top: -143px;
  }
`,
);

export const StyleWaveIcon = styled.div`
  /* @media screen and (min-width: 768px) { */
  @media screen and (min-width: 913px) {
    .mobile-curve-img {
      display: none;
    }
  }

  @media screen and (max-width: 768px) {
    .ant-image {
      position: static !important;
    }
    .curve-image {
      display: none;
    }
    .classic-modal-curve {
      left: 0 !important;
    }
  }
`;

export const RegisterThemeLogo = styled.div`
  padding: 8px 16px;
  border: 1px solid #ced4db;
  width: fit-content;
  display: flex;
  gap: 10px;
  align-items: center;
  background: #f6f6fa;
  color: black;
  height: 65px;
`;

export const DataFieldWrap = styled(Section)`
  display: flex;
  flex-direction: column;
  padding: 24px;
  gap: 16px;
  background: #ffffff;
  box-shadow: 0px 2px 8px rgba(33, 37, 41, 0.05);
  border-radius: 8px;
  .ant-form {
    display: flex;
    flex-direction: column;
  }
  .ant-form-item {
    display: flex;
    flex-direction: column;
    padding: 24px;
    background: #f8f9fa;
    border-radius: 4px;
  }
  .options {
    padding: 4px 24px 24px;
    .anticon {
      justify-content: center;
    }
  }
  .ant-input {
    box-sizing: border-box;
    padding: 5px 12px;
    height: 32px;
    background: #f8f9fa;
    border: 1px solid #ced4db;
    border-radius: 2px;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
  }
  .ant-form-item-label > label {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #6e777e;
  }
  .ant-form-item-control-input-content {
    display: flex;
    flex-direction: column;
  }
  .add-btn {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 6px 24px 6px 0px;
    gap: 10px;
    border-radius: 4px;
    border: none;
    background: transparent;
  }
  .anticon-delete {
    color: #6e777e;
  }
  .ant-card {
    padding: 0px;
    gap: 0px;
    border-radius: 0px;
    border: none;
  }
  .ant-card-body {
    padding: 0px;
    border-radius: 0px;
  }
`;

export const FieldNameWrap = styled(Section)`
  display: flex;
  align-items: flex-end;
  gap: 24px;
`;

export const IconWrap = styled(Section)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 6px 0px;
  gap: 16px;
`;

export const ListingWrap = styled(Section)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 8px 0px 0px;
  gap: 8px;
`;

export const OptionWrap = styled(Section)`
  display: flex;
  align-items: center;
  gap: 5px;
`;

export const RegionInputWrap = styled(Section)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  width: 100%;
`;

export const RegionWrap = styled(Section)`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 24px;
`;

export const SwitchWrap = styled(Section)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 6px 8px 6px 0px;
  gap: 10px;
  .ant-typography {
    word-break: normal;
  }
`;

export const UpperWrap = styled(Section)`
  display: flex;
  padding: 8px 0px 0px;
  gap: 16px;
  .ant-btn {
    background: #ffffff;
    border: 0.5px solid #ced4db;
    border-radius: 4px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 6px 8px;
    gap: 8px;
    height: 32px;
  }
  .ant-btn > span {
    font-weight: 600;
    font-size: 14px;
    line-height: 22px;
    color: #4a5158;
  }
`;

export const HelpContentWrap = styled(Section)`
  display: flex;
  align-items: center;
  .ant-btn.ant-btn-block {
    display: flex;
    gap: 8px;
    padding: 5px 12px;
    align-items: center;
  }
  .ant-btn-text:not(:disabled):hover {
    border-radius: 0px;
  }
  .profile-dropdown > span {
    color: #202428 !important;
  }
  .simple-dropdown > span {
    color: #202428 !important;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
  }
  .ant-btn > span {
    color: #4a5158;
    margin-inline-start: 0px;
  }
  &:hover {
    background: #e9ecef;
  }
`;

export const FieldDropWrap = styled.div`
  .simple-dropdown {
    padding: 5px 32px 5px 12px;
  }
`;

export const ModalDataWrap = styled(Section)`
  display: flex;
  flex-direction: column;
  width: 482px;
  gap: 1em;
  align-items: center;
  margin-bottom: 32px;
  .ant-typography {
    text-align: center;
  }
  @media screen and (max-width: 1000px) {
    width: 250px !important;
  }
  @media screen and (max-width: 768px) {
    margin-bottom: 24px;
    .success-msg {
      font-size: 14px !important;
      line-height: 22px !important;
    }
  }
`;

export const ModalWrap = styled(Card)`
  display: flex;
  flex-direction: column;
  padding: 80px 200px;
  background: #ffffff;
  box-shadow: 0 1em 1.5em rgba(33, 37, 41, 0.12);
  border-radius: 0.5em;
  align-items: center;
  gap: 2em;
  z-index: 1;
  width: 792px;
  margin: auto;

  .ant-card-body {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 !important;
  }
  .ant-btn {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0.375em 1.5em;
    gap: 0.625em;
    height: 2.5em;
    background: #ff3621;
    border-radius: 0.25em;
  }
  .ant-btn > span {
    line-height: normal;
  }
  .ant-btn-default:hover {
    color: #16277d;
    border-color: transparent;
  }
  .ant-btn {
    color: #16277d;
    border-color: transparent;
  }
  @media screen and (max-width: 768px) {
    padding: 24px 12px;
    border: 1px solid #e9ecef;
    box-shadow: 0px 16px 24px rgba(33, 37, 41, 0.12);
  }
`;

export const RegistrationModalWrap = styled(Section)`
  display: flex;
  align-items: center;
  height: calc(100vh - 80px);
  @media screen and (max-width: 768px) {
    padding-left: 16px;
    padding-right: 16px;
  }
`;

export const RegistrationFooterWrap = styled(Section)({}, ({ isClean }: { isClean: boolean }) => ({
  position: "absolute",
  bottom: isClean ? "82px" : "16px",
  right: "32px",
}));

export const FormBottomWrap = styled(Section)`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  background-color: #fff;
  width: 100%;
  .ant-btn {
    height: 48px;
  }
`;

export const BoredredWrap = styled(Section)`
  border: 1px solid #f0f0f0;
  border-radius: 8px 8px 0 0;
  .clean-bottom {
    bottom: 82px !important;
  }
`;

export const PurchaseDetailsWrap = styled(Card)`
  width: fit-content;
  z-index: 1;
  position: absolute;
  bottom: 116px;
  max-width: 482px;
  width: 80%;
  background: #ffffff;
  border-top: 1px solid #ced4db;
  box-shadow: 0px 4px 8px rgba(33, 37, 41, 0.15);
  border-radius: 8px;
  .ant-card-body {
    padding: 16px;
  }
  @media screen and (max-width: 768px) {
    bottom: 60px;
  }
  @media screen and (max-width: 981px) {
    width: 100%;
  }
  @media screen and (max-width: 768px) {
    width: 96%;
    position: static;
    gap: 8px !important;
    bottom: 0;
    max-width: 100%;
    margin-top: 40px;
  }
  @media screen and (max-width: 414px) {
    width: 92%;
  }
`;

export const ListsWrap = styled(Section)`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  gap: 12px;
  margin-top: 8px;
  :first-child {
    margin-top: 16px;
  }
`;

export const ListItem = styled(Text)`
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #343b41;
  min-width: 126px;
`;

export const StyledWave = styled(Section)(
  {},
  ({ brandColor }: any) => `
  display: block;
  position: absolute;
  bottom: 40%;
  height: 40px;
  width: 100%;
  background: ${brandColor || "#5E6FD0"};
  transform: scale(1, 3) rotateY(180deg);

  :before {
    content: "";
    display: block;
    position: absolute;
    border-radius: 100%;
    width: 100%;
    height: 300px;
    background-color: ${brandColor || "#5E6FD0"};
    left: -34%;
    top: -240px;
    clip-path: ellipse(100% 15% at -15% 100%);
  }

  ::after {
    content: "";
    display: block;
    position: absolute;
    border-radius: 100%;
    width: 100%;
    height: 296px;
    background-color: white;
    right: -25%;
    top: 0px;
  }
  @media only screen and (min-height: 800px) {
    bottom: 50%;
  }
  // @media screen and (max-width: 767px) {
  //   display: none;
  // }
  @media screen and (max-width: 912px) {
    display: none;
  }
`,
);

export const MainWrap = styled(Section)`
  overflow: hidden;
`;
